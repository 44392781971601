<template>
  <AppPage :fix-header="true" :show-footer="false" class="centered">
    <div class="app-width" v-if="plans">
      <div>
        <h1>{{ pageTitle }}</h1>
        <p >{{ pageParagraph }}</p>
      </div>
      <div class="discount-toggle">
        <span>
          Yearly billing discount
          <span class="green bold">-15%</span>
        </span>
        <InputToggle size="xl" v-model="yearInterval" :color="{ checked: '#4f4fff', unchecked: '#000000' }"/>
      </div>
      <div class="plan-tabs">
        <div
          v-for="(plan, index) in plans"
          :key="index"
          class="plan-tab"
          :class="{selected: selectedPlanIndex === index}"
          @click="selectedPlanIndex = index"
        >
          {{plan.title}}
        </div>
      </div>
      <div class="plan-container">
        <PlanColumn
          v-for="(plan, index) in plans"
          :key="index"
          :plan="plan"
          :interval="selectedIntervalName"
          :highlighted="index === 1"
          :class="{selected: selectedPlanIndex === index}"
          @toggleInterval="yearInterval=!yearInterval"
          @signup="$router.push({name:'signup'})"
        />
      </div>
    </div>
  </AppPage>
</template>

<script lang="ts">
import InputToggle from "@/components/atoms/InputToggle.vue";
import PlanColumn from "@/components/organisms/PlanColumn.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin.js";
import { mapGetters } from 'vuex';

export default {
  mixins: [pageHeadMixin],
  components: {
    PlanColumn,
    InputToggle,
    AppPage
  },
  data(){
    return {
      head:{
        title: 'Plans & Pricing',
        description: 'StoryFolder is Free. Upgrade for advanced features for turning your videos into storyboards, production notes and pitches.'
      },
      yearInterval: true,
      selectedPlanIndex: 1
    }
  },
  computed: {
    ...mapGetters('coupon', ['hasCoupon', 'couponDescription', 'expires', 'metadata']),
    pageTitle() {
      if(this.hasCoupon){
        return this.coupon.name;
      }
      return this.$store.getters['user/isSignedIn'] ? 'Select a Plan' : 'Start Free for 14 Days'
    },
    pageParagraph(){
      if(this.offerFreeTrial){
        return 'You won\'t be charged until your free trial ends. Cancel any time.'
      }else{
        return this.couponDescription + ' Cancel any time.'
      }
    },
    plans() {
      return this.$store.getters['plans/withCoupon'];
    },
    selectedIntervalName() {
      return this.yearInterval ? 'year' : 'month'
    },
    offerFreeTrial(){
      // Do not offer a trial if a coupon is used
      return !this.hasCoupon;
    },
    coupon(){
      return this.$store.getters['coupon/get'];
    },
  },
  watch: {
    expires: {
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch('coupon/startCountdown');
        } else {
          this.$store.dispatch('coupon/stopCountdown');
        }
      },
      immediate: true
    }
  },
  created(){
    this.loadPlans();
  },
  methods: {
    loadPlans(){
      this.$store.dispatch('plans/load');
    }
  }
}
</script>

<style scoped lang="less">
@import '../less/mixins';
@import '../less/variables';

.app-width{
  padding: 0 1em;
  margin-top: 100px;
  text-align: center;
}

h1{
  font-weight: 500;
}
p.free a{
  display: inline;
}
button{
  padding: 0.75em 0.75em 0.75em 1.5em;
}
button.back{
  position: absolute;
  top: 2em;
  left: 2em;
  padding: 0.75em 1em 0.75em 1em;
}
.green{
  color: @green;
}
.discount-toggle{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: flex-end;
}
.plan-container{
  display: flex;
  justify-content: stretch;
  width: 100%;
  margin-top: 1em;
  gap: 0.5em;
  flex-direction: column;
}

.plan-column{
  text-align: left;
  padding: 1em 1.5em;
  width: 100%;
  display: none;
  &:first-child /deep/ h2{
    color: @white;
  }
  &:nth-child(2) /deep/ h2{
    color: @white;
  }
  &:last-child{
    /deep/ h2{
      color: white;
    }
  }
  &.selected{
    display: block;
  }
}

.plan-tabs{
  background: @black;
  border-radius: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: hidden;
  cursor: pointer;
  font-size: 1.25rem;
  margin-top: 1em;
  margin-bottom: 1.5em;
  border:2px solid @purpleVibrant;
  .plan-tab{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1em;
    color: @white;
    &.selected{
      background: @purpleVibrant;
    }
  }
}


@media(min-width: @fourthbreakpoint){
  .highlighted{
    position: relative;
    top: -20px;
  }
  .plan-container{
    flex-direction: row;
  }
  .plan-column{
    display: block;
  }
  .plan-tabs{
    display: none;
  }
}
</style>
