<template>
  <toggle-button
    :value="value"
    :color="color"
    @change="$emit('input', $event.value)"
    :height="selectedSize.height"
    :width="selectedSize.width"
    sync
  />
</template>
<script lang="ts">
import BaseInput from '@/components/atoms/BaseInput.vue'
import Vue from 'vue'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton);

export default Vue.extend({
  extends: BaseInput,
  props: {
    size: {
      type: String,
      default: 'small'
    },
    color: {
      type: Object,
      default: () => ({ checked: '#4f4fff', unchecked: '#111111' })
    }
  },
  data(){
    return {
      sizes: {
        small: {
          height: 18,
          width: 40
        },
        medium: {
          height: 22,
          width: 40
        },
        large: {
          height: 24,
          width: 45
        },
        xl: {
          height: 30,
          width: 50
        }
      }
    }
  },
  computed: {
    selectedSize(){
      if(this.sizes[this.size]){
        return this.sizes[this.size]
      }
      return this.sizes['medium']
    }
  }
})

</script>
