<template>
  <div
    :class="{
      'plan-column': true,
      highlighted,
      'discounted-monthly': showDiscount,
      'discounted-annually': showAnnualDiscount
  }">
    <h2>{{ plan.title }}</h2>
    <p class="best-for">{{ plan.bestFor }}</p>
    <p class="price" v-if="highestPricePerMonth">
      <span class="price">
        <span class="regular">
          {{formatPrice(highestPricePerMonth)}}
        </span>
        <span class="discounted" v-if="showDiscount">
          {{formatPrice(discountedPricePerMonth)}}
        </span>
      </span>
      /mo billed {{intervalIsYear ? 'annualy' : 'monthly'}}
    </p>
    <SmoothReflow>
      <div>
        <div class="period-container" v-if="highestPricePerMonth">
          <InputToggle :value="interval === 'year'" @input="$emit('toggleInterval')" :color="toggleColors" />
          <span>
            {{interval === 'year' ? 'Saving':'Save'}} with yearly billing
          </span>
          <span class="price-annual">
            <span class="regular">
              {{formatPrice(highestPricePerYear)}}
            </span>
            <span class="discounted" v-if="showAnnualDiscount">
              <br />{{formatPrice(discountedPricePerYear)}}
            </span>
            / year
          </span>
          <span class="savings">
            <span class="tag">{{formatPrice(totalPossibleSavings)}} off</span>
          </span>
        </div>
        <Button
            target="_blank"
            :to="checkoutLink"
            v-if="!$store.getters['subscription/isSubscribed']"
            :class="`${highlighted ? 'shimmer':'primary'} full-width`"
            @click="checkoutLink ? null : $emit('signup')"
        >
          {{offerFreeTrial ? 'Start Free Trial' : 'Get Deal' }}
        </Button>
      </div>
    </SmoothReflow>
    <p>
      {{plan.relativeDescription}}
    </p>
    <ul class="features">
      <li v-for="feature in plan.featureNames" :key="feature">
        <IconSvg name="check" />
        {{feature}}
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Button from "@/components/atoms/Button.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import InputToggle from "@/components/atoms/InputToggle.vue";
import SmoothReflow from "@/components/atoms/SmoothReflow.vue";

export default Vue.extend({
  components: {SmoothReflow, InputToggle, IconSvg, Button},
  props: {
    plan: {
      type: Object,
      required: true
    },
    interval: {
      type: String,
      required: true,
      default: 'year'
    },
    highlighted: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      toggleColors: { checked: '#4f4fff', unchecked: '#000000' }
    }
  },
  computed: {
    isAnnual(){
      return this.interval === 'year'
    },
    isMonthly(){
      return this.interval === 'month'
    },
    highestPricePerMonth() {
      return this.plan.periods.month.price
    },
    highestPricePerYear(){
      return this.highestPricePerMonth * 12
    },
    discountedPricePerMonth() {
      if(this.isAnnual && this.plan.percentOff.year){
        return (this.plan.periods.year.price / 12) * (1 - this.plan.percentOff.year / 100)
      }else if(this.isMonthly && this.plan.percentOff.month){
        return this.plan.periods.month.price * (1 - this.plan.percentOff.month / 100)
      }else if(this.isAnnual){
        return this.plan.periods.year.price / 12
      }
      return this.highestPricePerMonth;
    },
    discountedPricePerYear(){
      if(this.discountedPricePerMonth){
        if(this.isAnnual && this.plan.percentOff.year){
          return this.plan.periods.year.price * (1 - this.plan.percentOff.year / 100)
        }else if(this.isMonthly && this.plan.percentOff.month){
          return this.plan.periods.year.price * (1 - this.plan.percentOff.month / 100)
        }
        return this.plan.periods.year.price
      }
      return this.highestPricePerYear;
    },
    showDiscount(){
      return this.highestPricePerMonth > this.discountedPricePerMonth
    },
    showAnnualDiscount(){
      return this.highestPricePerYear > this.discountedPricePerYear
    },
    totalPossibleSavings(){
      return Number((this.highestPricePerMonth * 12) - this.discountedPricePerYear).toFixed(2)
    },
    intervalIsYear(){
      return this.isAnnual
    },
    offerFreeTrial(){
      return (this.isAnnual && this.plan.percentOff.year === 0) ||
          (this.isMonthly && this.plan.percentOff.month === 0)
    },
    checkoutLink(){
      if(this.highestPricePerMonth) {
        const price = this.isAnnual ? this.plan.periods.year : this.plan.periods.month;
        console.log(`Buying ${this.plan.title} plan ${this.isAnnual ? 'yearly' : 'monthly'} using price`, price.price)

        console.log('checking out');
        // Base URL
        let baseUrl = '/api/users/checkout';

        // Create URLSearchParams object
        let params = new URLSearchParams();

        // Add mandatory parameters
        params.append('plan', this.plan._id);
        params.append('interval', this.isAnnual ? 'year' : 'month');

        // Add optional parameters if they exist
        if (this.$store.getters['user/email']) {
          params.append('email', this.$store.getters['user/email']);
        }
        const coupon = this.$store.getters['coupon/get'];
        if (coupon) {
          params.append('coupon', coupon.id);
        }

        // Build the full URL
        return `${baseUrl}?${params.toString()}`;
      }
      return null;
    }
  },
  methods: {
    formatPrice(number) {
      number = Number(number);
      return `$${Number.isInteger(number) ? number : number.toFixed(2)}`;
    }
  }
})

</script>
<style lang="less" scoped>
  @import '../../less/variables';
  .plan-column{
    background: @black;
    border-radius: 1em;
    box-sizing: border-box;
  }
  .highlighted{
    background: @black;
    border:2px solid @purpleVibrant;
  }
  .current-plan{
    background: @purpleVibrant;
    color: @white;
    display: inline-block;
    padding: 0.25em 1em 0.2em 1em;
    border-radius: 2em;
    font-size: 80%;
  }
  h2{
    font-weight: 600;
    margin-bottom: 0.25em;
  }
  p{
    color: @grayMedium;
  }
  .best-for{
    margin-top: 0;
  }
  p.price{
    font-size: 90%;
  }
  span.price{
    font-size: 1.75em;
    font-weight: 600;
    color: @white;
    span.discounted{
      color: @color6;
      margin-left: 0.25em;
    }
  }
  .plan-column.discounted-monthly .price span.regular{
    text-decoration: line-through;
    color: @grayMedium;
  }
  .plan-column.discounted-annually .price-annual span.regular{
    text-decoration: line-through;
    color: @grayMedium;
  }
  .period-container{
    display: grid;
    grid-template-columns: auto auto;
    padding: 0.75em;
    background: @grayDark;
    border-radius: 0.25em;
    gap: 0.5em;
    font-size: 90%;
    span{
      &:nth-child(even){
        text-align: right;
      }
    }
    .savings .tag{
      background: @green;
      color: @black;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-weight: 600;
    }
  }
  a.full-width{
    display: block;
    width: auto;
    text-align: center;
  }
  ul.features{
    list-style: none;
    padding: 0;
    margin: 0;
    color: @white;
    li{
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
      gap: 1em;
    }
  }

  /deep/.shimmer{
    background: -webkit-linear-gradient(0deg, #015ce5, #a340f5, #f5409b, #fc9c61);
    background-size: 200% 10px !important;
    animation: shimmer 1.5s ease-out infinite !important;
    font-size: 120%;
  }
  @keyframes shimmer{
    0%{
      background-position: 0 0;
    }
    50%{
      background-position: 100% 0;
    }
    100%{
      background-position: 0 0;
    }
  }
</style>
